import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { Link } from 'gatsby'

import pic11 from '../assets/images/pic11.jpg'

const Generic = props => (
  <Layout>
    <Helmet>
      <title>Thanh Le College, School of Cosmetology - Financial Aid</title>
      <meta name="description" content="Financial Aid" />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>Financial Aid</h1>
          </header>
          <span className="image main">
            <img src={pic11} alt="" />
          </span>
          <p>
            Financial aid is a mechanism that reduces out-of-pocket costs that
            the student and/or the student’s parents must pay to obtain a
            specific postsecondary education. Presented differently, financial
            aid is money made available to help students meet the cost of
            attending college. Financial Aid is made up of grants and loans.
            Grants are not to be repaid. Loans usually have low interest rates
            and a student must re-pay a loan according to the individual loan
            program terms. Most of the loans can be arranged to require payment
            after a grace period of several months following graduation, the
            student’s termination from the program or the point at which the
            student’s attendance falls below half time. Financial Aid is awarded
            to students who have “need”. Need is defined as the difference
            between the amount of money that the family will be expected to
            contribute to meet student costs and the cost of education at this
            school.
          </p>
          <p>
            Based on a combination of approvals, authorization and
            accreditation, our students are eligible to apply for and receive
            tuition aid and financial assistance while attending the college. A
            list of these programs includes: Federal PELL Grant (FPELL) and
            Federal Supplemental Education Opportunity Grant (FSEOG), neither of
            which requires repayment.
          </p>
          <p>
            Students desiring information regarding the various aids available
            should contact the Financial Aid office by visiting our school or
            contacting our Financial Aid Officer at (714) 740-2755.
          </p>
          <p>
            Visit the{' '}
            <Link to="/net-price-calculator">
              U.S. Department of Education Net Price Calculator website
            </Link>{' '}
            to calculate how much you can qualify for in financial aid.{' '}
          </p>
          <p>
            <Link to="/net-price-calculator" className="button">
              Net Price Calculator
            </Link>
          </p>
        </div>
      </section>
    </div>
  </Layout>
)

export default Generic
